// src/hooks/dataHooks.ts

import { useState, useEffect, useCallback } from "react";
import useApiRequest from "../hooks/useApiRequest";
import { Category, CategoryOption } from "../types/category";

interface UseFetchCategoriesReturn {
  categoryOptions: CategoryOption[];
  fetchCategories: () => Promise<void>;
}

export const useFetchCategories = (): UseFetchCategoriesReturn => {
  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
  const apiRequest = useApiRequest();

  const fetchCategories = useCallback(async () => {
    try {
      const response = await apiRequest({
        url: "/hive/categories",
        shouldCache: true,
      });

      if (!response.ok) {
        throw new Error(`Error fetching categories: ${response.statusText}`);
      }

      const data: Category[] = await response.json();

      const formattedOptions: CategoryOption[] = data.map((category) => ({
        value: category.id,
        label: category.name,
        description: category.description,
      }));

      setCategoryOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, [apiRequest]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return { categoryOptions, fetchCategories };
};
