// src/data/biodata_fields.js

import countiesAdministrativeMap from "./counties_administrative_map.json";

export const getBioDataFields = () => {
  return [
    {
      name: "farmer_id",
      label: "Farmer ID",
      inputType: "number",
      placeholder: "Enter Farmer ID",
      defaultMasked: false,
      tooltip: "Unique identifier for each farmer.",
    },
    {
      name: "national_id_no",
      label: "National ID No",
      inputType: "number",
      placeholder: "Enter National ID Number",
      defaultMasked: true,
      tooltip: "Official national identification number.",
    },
    {
      name: "farmer_name",
      label: "Farmer Name",
      inputType: "text",
      placeholder: "Enter Farmer Name",
      defaultMasked: true,
      tooltip: "Full name of the farmer.",
    },
    {
      name: "registration_status",
      label: "Registration Status",
      inputType: "text",
      placeholder: "Enter Registration Status",
      defaultMasked: false,
      hidden: true,
      tooltip: "Current status of farmer registration.",
    },
    {
      name: "farmer_status",
      label: "Farmer Status",
      inputType: "text",
      placeholder: "Enter Farmer Status",
      defaultMasked: false,
      hidden: true,
      tooltip: "Active or inactive status of the farmer.",
    },
    {
      name: "date_of_registration",
      label: "Date of Registration",
      inputType: "date",
      defaultMasked: false,
      tooltip: "The date when the farmer registered.",
    },
    {
      name: "start_of_registration",
      label: "Start of Registration",
      inputType: "date",
      defaultMasked: false,
      hidden: true,
      tooltip: "Start date of the registration period.",
    },
    {
      name: "end_of_registration",
      label: "End of Registration",
      inputType: "date",
      defaultMasked: false,
      hidden: true,
      tooltip: "End date of the registration period.",
    },
    {
      name: "county",
      label: "County",
      inputType: "select",
      hidden: false,
      defaultMasked: false,
      options: countiesAdministrativeMap.counties.map((county) => county.name),
      tooltip: "Select the county of the farmer.",
    },
    {
      name: "subcounty",
      label: "Subcounty",
      inputType: "select",
      hidden: false,
      defaultMasked: false,
      options: [], // To be populated based on selected county
      tooltip: "Select the subcounty based on the selected county.",
    },
    {
      name: "ward",
      label: "Ward",
      inputType: "select",
      hidden: false,
      defaultMasked: false,
      options: [], // To be populated based on selected subcounty
      tooltip: "Select the ward based on the selected subcounty.",
    },
    {
      name: "constituency",
      label: "Constituency",
      inputType: "text",
      placeholder: "Enter Constituency",
      defaultMasked: false,
      tooltip: "Constituency of the farmer.",
      hidden: true,
    },
    {
      name: "division",
      label: "Division",
      inputType: "text",
      placeholder: "Enter Division",
      defaultMasked: false,
      tooltip: "Division within the ward.",
      hidden: true,
    },
    {
      name: "location",
      label: "Location",
      inputType: "text",
      placeholder: "Enter Location",
      defaultMasked: false,
      tooltip: "Specific location details.",
      hidden: true,
    },
    {
      name: "sublocation",
      label: "Sublocation",
      inputType: "text",
      placeholder: "Enter Sublocation",
      defaultMasked: false,
      tooltip: "Detailed sublocation information.",
      hidden: true,
    },
    {
      name: "village_name",
      label: "Village Name",
      inputType: "text",
      placeholder: "Enter Village Name",
      defaultMasked: false,
      tooltip: "Name of the village.",
      hidden: true,
    },
    {
      name: "enumeration_area_number",
      label: "Enumeration Area Number",
      inputType: "text",
      placeholder: "Enter Enumeration Area Number",
      defaultMasked: false,
      tooltip: "Enumeration area identifier.",
      hidden: true,
    },
    {
      name: "shopping_center",
      label: "Shopping Center",
      inputType: "text",
      placeholder: "Enter Shopping Center",
      defaultMasked: false,
      tooltip: "Nearest shopping center.",
      hidden: true,
    },
    {
      name: "year_of_birth",
      label: "Year of Birth",
      inputType: "number",
      placeholder: "Enter Year of Birth",
      defaultMasked: true,
      tooltip: "Year when the farmer was born.",
    },
    {
      name: "gender",
      label: "Gender",
      inputType: "radio",
      options: [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Other", value: "Other" },
      ],
      defaultMasked: true,
      tooltip: "Select the gender of the farmer.",
    },
    {
      name: "email",
      label: "Email Address",
      inputType: "text",
      placeholder: "Enter Email Address",
      defaultMasked: true,
      tooltip: "Email address of the farmer.",
      hidden: true,
    },
    {
      name: "postal_address",
      label: "Postal Address",
      inputType: "text",
      placeholder: "Enter Postal Address",
      defaultMasked: true,
      tooltip: "Postal address of the farmer.",
      hidden: true,
    },
    {
      name: "postal_code",
      label: "Postal Code",
      inputType: "text",
      placeholder: "Enter Postal Code",
      defaultMasked: true,
      tooltip: "Postal code of the farmer's address.",
      hidden: true,
    },
    {
      name: "farmer_mobile_number",
      label: "Farmer Mobile Number",
      inputType: "text",
      placeholder: "Enter Farmer Mobile Number",
      defaultMasked: true,
      tooltip: "Contact number of the farmer.",
    },
    {
      name: "marital_status",
      label: "Marital Status",
      inputType: "text",
      placeholder: "Enter Marital Status",
      defaultMasked: false,
      tooltip: "Marital status of the farmer.",
    },
    {
      name: "household_size",
      label: "Household Size",
      inputType: "number",
      placeholder: "Enter Household Size",
      defaultMasked: false,
      tooltip: "Number of people in the household.",
    },
    {
      name: "highest_level_of_formal_education",
      label: "Highest Level of Formal Education",
      inputType: "text",
      placeholder: "Enter Education Level",
      defaultMasked: false,
      tooltip: "Highest education level attained.",
    },
    {
      name: "formal_training_in_agriculture",
      label: "Formal Training in Agriculture",
      inputType: "radio",
      options: [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
      ],
      defaultMasked: false,
      tooltip: "Whether the farmer has formal training in agriculture.",
    },
    {
      name: "crop_production",
      label: "Crop Production",
      inputType: "radio",
      options: [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
      ],
      defaultMasked: false,
      tooltip: "Whether the farmer is involved in crop production.",
    },
    {
      name: "livestock_production",
      label: "Livestock Production",
      inputType: "radio",
      options: [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
      ],
      defaultMasked: false,
      tooltip: "Whether the farmer is involved in livestock production.",
    },
    {
      name: "fish_farming",
      label: "Fish Farming",
      inputType: "radio",
      options: [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
      ],
      defaultMasked: false,
      tooltip: "Whether the farmer is involved in fish farming.",
    },
    {
      name: "respondent_name",
      label: "Respondent Name",
      inputType: "text",
      placeholder: "Enter Respondent Name",
      defaultMasked: true,
      tooltip: "Name of the respondent.",
      hidden: true,
    },
    {
      name: "respondent_national_id",
      label: "Respondent National ID",
      inputType: "number",
      placeholder: "Enter Respondent National ID",
      defaultMasked: true,
      tooltip: "National ID of the respondent.",
      hidden: true,
    },
    {
      name: "respondent_mobile_telephone_number",
      label: "Respondent Mobile Telephone Number",
      inputType: "text",
      placeholder: "Enter Respondent Mobile Number",
      defaultMasked: true,
      tooltip: "Contact number of the respondent.",
      hidden: true,
    },
    {
      name: "farm_name",
      label: "Farm Name",
      inputType: "text",
      placeholder: "Enter Farm Name",
      defaultMasked: true,
      tooltip: "Name of the farm.",
      hidden: true,
    },
    {
      name: "gps_latitude",
      label: "GPS Latitude",
      inputType: "number",
      placeholder: "Enter GPS Latitude",
      defaultMasked: false,
      tooltip: "Latitude coordinate of the farmer's location.",
      hidden: true,
    },
    {
      name: "gps_longitude",
      label: "GPS Longitude",
      inputType: "number",
      placeholder: "Enter GPS Longitude",
      defaultMasked: false,
      tooltip: "Longitude coordinate of the farmer's location.",
      hidden: true,
    },
    {
      name: "accuracy_level",
      label: "Accuracy Level",
      inputType: "number",
      placeholder: "Enter Accuracy Level",
      defaultMasked: false,
      tooltip: "Accuracy level of the GPS data.",
      hidden: true,
    },
    {
      name: "data_source",
      label: "Data Source",
      inputType: "text",
      placeholder: "Enter Data Source",
      defaultMasked: false,
      tooltip: "Source from which the data was obtained.",
      hidden: true,
    },
  ];
};
