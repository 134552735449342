// src/components/NewDataRequest/Group.jsx

import React from "react";
import { Button, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import Rule from "./Rule";
import PropTypes from "prop-types";

const Group = ({
  group,
  parentGroupId,
  fieldsData,
  handleFieldChange,
  handleOperatorChange,
  handleValueChange,
  handleValue2Change,
  addRule,
  addGroup,
  removeRule,
  removeGroup,
  getFieldsForRule,
  getCountyData,
  getSubcountyData,
  getLivestockCategoryData,
  getLivestockSubcategoryData,
  handleCombinatorChange,
  isCountyAccount, // New prop
}) => {
  // Determine if the group contains any auto-added rules
  const hasAutoRules = group.rules.some((rule) => rule.isAuto);

  return (
    <div
      className="mb-4 p-3 border rounded"
      style={{ marginLeft: parentGroupId ? 20 : 0 }}
    >
      {/* Group Header */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-combinator-${group.id}`}>
                Combines the rules within this group using AND or OR.
              </Tooltip>
            }
          >
            <label className="me-2">Combinator:</label>
          </OverlayTrigger>
          <Form.Select
            className="d-inline-block w-auto"
            value={group.combinator}
            onChange={(e) => handleCombinatorChange(group.id, e.target.value)}
            title="Select how to combine rules within this group"
          >
            <option value="AND">AND</option>
            <option value="OR">OR</option>
          </Form.Select>
        </div>
        {parentGroupId && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-remove-group-${group.id}`}>
                Remove this group
              </Tooltip>
            }
          >
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => removeGroup(parentGroupId, group.id)}
              title="Remove Group"
              disabled={hasAutoRules} // Disable if the group contains auto-added rules
            >
              <i className="bi bi-trash"></i> Remove Group
            </Button>
          </OverlayTrigger>
        )}
      </div>

      {/* Add Filter and Add Group Buttons */}
      <div className="d-flex justify-content-start mb-2">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-add-rule-${group.id}`}>
              Add a new rule to this group
            </Tooltip>
          }
        >
          <Button
            variant="primary"
            onClick={() => addRule(group.id)}
            title="Add Filter"
            className="me-2 d-flex align-items-center"
          >
            <i className="bi bi-plus-circle-fill me-2"></i> Add Filter
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-add-group-${group.id}`}>
              Add a new subgroup to this group
            </Tooltip>
          }
        >
          <Button
            variant="info"
            onClick={() => addGroup(group.id)}
            title="Add Group"
            className="d-flex align-items-center"
          >
            <i className="bi bi-plus-circle-fill me-2"></i> Add Group
          </Button>
        </OverlayTrigger>
      </div>

      {/* Rules in the Group */}
      {group.rules.map((rule) => (
        <Rule
          key={rule.id}
          rule={rule}
          groupId={group.id}
          fieldsData={fieldsData}
          handleFieldChange={handleFieldChange}
          handleOperatorChange={handleOperatorChange}
          handleValueChange={handleValueChange}
          handleValue2Change={handleValue2Change}
          removeRule={removeRule}
          getFieldsForRule={getFieldsForRule}
          groupRules={group.rules}
          getCountyData={getCountyData}
          getSubcountyData={getSubcountyData}
          getLivestockCategoryData={getLivestockCategoryData}
          getLivestockSubcategoryData={getLivestockSubcategoryData}
          isCountyAccount={isCountyAccount} // Pass down the prop
        />
      ))}

      {/* Render Subgroups */}
      {group.groups.map((subGroup) => (
        <Group
          key={subGroup.id}
          group={subGroup}
          parentGroupId={group.id}
          fieldsData={fieldsData}
          handleFieldChange={handleFieldChange}
          handleOperatorChange={handleOperatorChange}
          handleValueChange={handleValueChange}
          handleValue2Change={handleValue2Change}
          addRule={addRule}
          addGroup={addGroup}
          removeRule={removeRule}
          removeGroup={removeGroup}
          getFieldsForRule={getFieldsForRule}
          getCountyData={getCountyData}
          getSubcountyData={getSubcountyData}
          getLivestockCategoryData={getLivestockCategoryData}
          getLivestockSubcategoryData={getLivestockSubcategoryData}
          handleCombinatorChange={handleCombinatorChange}
          isCountyAccount={isCountyAccount} // Pass down the prop
        />
      ))}
    </div>
  );
};

Group.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    combinator: PropTypes.string.isRequired,
    rules: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    parentId: PropTypes.string,
  }).isRequired,
  parentGroupId: PropTypes.string,
  fieldsData: PropTypes.array.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleOperatorChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  handleValue2Change: PropTypes.func.isRequired,
  addRule: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
  removeRule: PropTypes.func.isRequired,
  removeGroup: PropTypes.func.isRequired,
  getFieldsForRule: PropTypes.func.isRequired,
  getCountyData: PropTypes.func.isRequired,
  getSubcountyData: PropTypes.func.isRequired,
  getLivestockCategoryData: PropTypes.func.isRequired,
  getLivestockSubcategoryData: PropTypes.func.isRequired,
  handleCombinatorChange: PropTypes.func.isRequired,
  isCountyAccount: PropTypes.bool.isRequired, // New prop
};

export default Group;
