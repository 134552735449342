// src/data/fisheries_fields.js

export const getFisheriesFields = () => [
  {
    name: "entry_id",
    label: "Entry ID",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Unique identifier for the aquaculture species entry.",
  },
  {
    name: "farmer_id",
    label: "Farmer ID",
    inputType: "text",
    hidden: false,
    masked: false,
    tooltip: "Unique identifier for the farmer.",
  },
  {
    name: "aquaculture_species",
    label: "Aquaculture Species",
    inputType: "select",
    hidden: false,
    masked: false,
    options: ["Tilapia", "Prawns", "Catfish"],
    tooltip: "Species of aquaculture.",
  },
  {
    name: "aquaculture_species_category",
    label: "Aquaculture Species Category",
    inputType: "select",
    hidden: false,
    masked: false,
    options: ["Fresh Water", "Brackish"],
    tooltip: "Category of the aquaculture species.",
  },
  {
    name: "type_of_production_system",
    label: "Type of Production System",
    inputType: "select",
    hidden: false,
    masked: false,
    options: ["Cages", "Ponds", "Dams", "Race ways"],
    tooltip: "Type of production system used.",
  },
  {
    name: "estimated_no_of_fingerlings",
    label: "Estimated Number of Fingerlings",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Estimated number of fingerlings.",
  },
  {
    name: "unit_of_measure",
    label: "Unit of Measure",
    inputType: "select",
    hidden: false,
    masked: false,
    options: ["Volume m3", "Area m2"],
    tooltip: "Unit of measurement for the active area.",
  },
  {
    name: "active_area",
    label: "Active Area",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Active area used for aquaculture (in m3 or m2).",
  },
  {
    name: "no_of_active_units",
    label: "Number of Active Units",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Number of active production units.",
  },
  {
    name: "inactive_area",
    label: "Inactive Area",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Inactive area used for aquaculture (in m3 or m2).",
  },
  {
    name: "no_of_inactive_units",
    label: "Number of Inactive Units",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Number of inactive production units.",
  },
];
