// src/data/livestock_fields.js
import livestockHierarchy from "./livestock_hierarchy.json";

export const getLivestockFields = () => [
  {
    name: "entry_id",
    label: "Entry ID",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Unique identifier for the livestock entry.",
  },
  {
    name: "farmer_id",
    label: "Farmer ID",
    inputType: "text",
    hidden: false,
    masked: false,
    tooltip: "Unique identifier for the farmer.",
  },
  {
    name: "livestock_category",
    label: "Livestock Category",
    inputType: "select",
    hidden: false,
    masked: false,
    options: livestockHierarchy.livestock_categories.map((c) => c.category),
    tooltip: "Select a livestock category.",
  },
  {
    name: "livestock_sub_category",
    label: "Livestock Subcategory",
    inputType: "select",
    hidden: false,
    masked: false,
    options: [], // To be populated based on selected category
    tooltip: "Select a livestock subcategory.",
  },
  {
    name: "livestock_name",
    label: "Livestock Name",
    inputType: "select",
    hidden: false,
    masked: false,
    options: [], // To be populated based on selected subcategory
    tooltip: "Select a specific livestock name.",
  },
  {
    name: "livestock_detail",
    label: "Livestock Detail",
    inputType: "text",
    hidden: false,
    masked: false,
    tooltip: "Detailed information about the livestock.",
  },
  {
    name: "male_livestock_count",
    label: "Male Livestock Count",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Number of male livestock.",
  },
  {
    name: "female_livestock_count",
    label: "Female Livestock Count",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Number of female livestock.",
  },
  {
    name: "total_livestock_count",
    label: "Total Livestock Count",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Total number of livestock.",
  },
  {
    name: "production_system",
    label: "Production System",
    inputType: "select",
    hidden: false,
    masked: false,
    options: [
      "Semi zero grazing",
      "Free Range",
      "Rotational grazing/paddocking",
      "Ranching",
      "Pig Housing(Piggery)",
      "Zero grazing",
      "Slatted floor system",
      "Deep litter system",
      "Communal grazing",
      "Tethering",
      "Pastoralism",
    ],
    tooltip: "Production system used for livestock.",
  },
];
