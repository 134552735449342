// src/pages/approved-user/GetData.tsx

import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Card,
  Table,
  Spinner,
  Button,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "chart.js/auto";
import "reactjs-popup/dist/index.css";
import useApiRequest from "../../hooks/useApiRequest";
import useFileDownloadRequest from "../../hooks/useFileDownloadRequest";
import TermsAndConditionsModal from "../../components/TermsAndConditionsModal";
import { DataRequest } from "../../types/dataRequest";
import toast from "react-hot-toast";

// Define a type for your data items
interface DataItem {
  [key: string]: any;
}

interface LocationState {
  dataRequest: DataRequest;
}

const GetData: React.FC = () => {
  // State variables with type annotations
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [flashMessage, setFlashMessage] = useState<string | null>(null);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const [dataRequest, setDataRequest] = useState<DataRequest | null>(null);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [requestedFormat, setRequestedFormat] = useState<
    "csv" | "json" | "pdf" | null
  >(null);

  // Custom hooks
  const apiRequest = useApiRequest();
  const fileDownloadRequest = useFileDownloadRequest();

  // Extracting URL parameters with type safety
  const { requestId } = useParams<{ requestId: string }>();

  // Helper function to format bytes to MB/GB
  const formatSize = (bytes: number): string => {
    if (bytes >= 1_073_741_824) {
      return `${(bytes / 1_073_741_824).toFixed(2)} GB`;
    } else if (bytes >= 1_048_576) {
      return `${(bytes / 1_048_576).toFixed(2)} MB`;
    } else if (bytes >= 1024) {
      return `${(bytes / 1024).toFixed(2)} KB`;
    } else {
      return `${bytes} bytes`;
    }
  };

  // Fetch the latest dataRequest from the server
  useEffect(() => {
    const fetchDataRequest = async () => {
      setLoading(true);
      try {
        const apiUrl = `/data-requests/single-request/${requestId}`;
        const response = await apiRequest({
          url: apiUrl,
          method: "GET",
        });

        if (response.ok) {
          const fetchedDataRequest: DataRequest = await response.json();
          setDataRequest(fetchedDataRequest);
        } else {
          console.error("Error fetching data request:", response.statusText);
          setFlashMessage(
            "Error fetching data request. Please try again later.",
          );
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data request:", error);
        setFlashMessage("Error fetching data request. Please try again later.");
        setLoading(false);
      }
    };

    fetchDataRequest();
  }, [apiRequest, requestId]);

  // Polling effect to check for data availability
  useEffect(() => {
    let pollingInterval: NodeJS.Timeout;

    const pollDataRequest = async () => {
      try {
        const apiUrl = `/data-requests/single-request/${requestId}`;
        const response = await apiRequest({
          url: apiUrl,
          method: "GET",
        });

        if (response.ok) {
          const fetchedDataRequest: DataRequest = await response.json();
          setDataRequest(fetchedDataRequest);

          // Check if requested format is now available
          let formatAvailable = false;
          if (requestedFormat === "csv") {
            formatAvailable = fetchedDataRequest.csvAvailable;
          } else if (requestedFormat === "json") {
            formatAvailable = fetchedDataRequest.jsonAvailable;
          } else if (requestedFormat === "pdf") {
            formatAvailable = fetchedDataRequest.pdfAvailable;
          }

          if (formatAvailable) {
            // Stop polling
            setIsPolling(false);
            if (pollingInterval) {
              clearInterval(pollingInterval);
            }
            toast.success(
              `${requestedFormat?.toUpperCase()} data is now available for download.`,
            );
          }
        } else {
          console.error("Error polling data request:", response.statusText);
        }
      } catch (error) {
        console.error("Error polling data request:", error);
      }
    };

    if (isPolling) {
      // Start polling every 20 seconds
      pollingInterval = setInterval(pollDataRequest, 20000);

      // Fetch immediately
      pollDataRequest();
    }

    // Clean up on unmount or when isPolling changes
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [isPolling, apiRequest, requestId, requestedFormat]);

  // Effect to fetch data sample on component mount or when requestId changes
  useEffect(() => {
    const fetchSampleData = async () => {
      setInitLoading(true);
      try {
        const apiUrl = `/data-requests/requested-data/sample?requestId=${requestId}`;
        const response = await apiRequest({
          url: apiUrl,
          shouldCache: true,
        });

        if (response.ok) {
          const jsonData: DataItem[] = await response.json();
          setData(jsonData);
        } else {
          console.error("Error fetching data sample:", response.statusText);
          setFlashMessage("Error fetching data. Please try again later.");
        }
        setInitLoading(false);
      } catch (error) {
        console.error("Error fetching data sample:", error);
        setFlashMessage("Error fetching data. Please try again later.");
        setInitLoading(false);
      }
    };

    if (requestId) {
      fetchSampleData();
    }
  }, [apiRequest, requestId]);

  // Define data formats with formatted sizes
  const dataFormats = useMemo(
    () => [
      {
        format: "CSV",
        key: "csv",
        available: dataRequest?.csvAvailable || false,
        size: dataRequest?.csvFileSize
          ? formatSize(dataRequest.csvFileSize)
          : "N/A",
      },
      {
        format: "JSON",
        key: "json",
        available: dataRequest?.jsonAvailable || false,
        size: dataRequest?.jsonFileSize
          ? formatSize(dataRequest.jsonFileSize)
          : "N/A",
      },
      {
        format: "PDF",
        key: "pdf",
        available: dataRequest?.pdfAvailable || false,
        size: dataRequest?.pdfFileSize
          ? formatSize(dataRequest.pdfFileSize)
          : "N/A",
      },
    ],
    [dataRequest],
  );

  // Handler for accepting terms and conditions
  const handleTermsAccept = (accepted: boolean) => {
    setTermsAccepted(accepted);
  };

  // Handler for downloading files
  const handleDownload = async (fileType: "csv" | "json" | "pdf") => {
    setLoading(true);
    const endpoint = `/data-requests/requested-data/download?requestId=${requestId}&format=${fileType}`;

    try {
      const blob = await fileDownloadRequest(endpoint, fileType);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `downloaded_data.${fileType}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      toast.success(`${fileType.toUpperCase()} file downloaded successfully.`);
      setLoading(false);
    } catch (error) {
      console.error("Error downloading data:", error);
      toast.error(
        `Error downloading ${fileType.toUpperCase()} file. Please try again.`,
      );
      setLoading(false);
    }
  };

  // Handler for requesting data generation
  const handleRequestData = async (fileType: "csv" | "json" | "pdf") => {
    setLoading(true);
    try {
      const response = await apiRequest({
        url: `/data-requests/requested-data/generate?requestId=${requestId}&format=${fileType}`,
        method: "GET",
      });

      if (response.ok) {
        toast.success(
          `Your ${fileType.toUpperCase()} data request has been submitted. The data will be available for download once it's ready.`,
        );
        setRequestedFormat(fileType);
        setIsPolling(true); // Start polling
      } else {
        console.error("Error requesting data generation:", response.statusText);
        toast.error(
          "Error requesting data generation. Please try again later.",
        );
      }
      setLoading(false);
    } catch (error) {
      console.error("Error requesting data generation:", error);
      toast.error("Error requesting data generation. Please try again later.");
      setLoading(false);
    }
  };

  // Function to dynamically generate table headers based on data keys
  const generateTableHeaders = (data: DataItem[]): string[] => {
    if (!data || data.length === 0) return [];
    const headers = Object.keys(data[0]);
    return headers;
  };

  // Dynamically render table rows and cells
  const renderTableRows = (data: DataItem[]): JSX.Element[] => {
    return data.map((item, index) => (
      <tr key={index}>
        {Object.values(item).map((value, cellIndex) => (
          <td key={cellIndex}>{value}</td>
        ))}
      </tr>
    ));
  };

  return (
    <Card className="shadow mb-4">
      <Card.Header className="d-flex flex-row align-items-center justify-content-between">
        <h6>Use API Dataset</h6>
      </Card.Header>
      <Card.Body>
        {/* Flash Message */}
        {flashMessage && (
          <Alert
            variant="info"
            onClose={() => setFlashMessage(null)}
            dismissible
          >
            {flashMessage}
          </Alert>
        )}

        {/* Loading Spinner */}
        {loading || initLoading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : data && data.length > 0 ? (
          <>
            {/* Data Table */}
            <div className="table-container">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {generateTableHeaders(data).map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{renderTableRows(data)}</tbody>
              </Table>
            </div>

            {/* Actions Section */}
            <div>
              {/* Terms and Conditions Button */}
              <Button
                variant={!termsAccepted ? "warning" : "primary"}
                onClick={() => setShowTermsModal(true)}
                className="mb-2"
              >
                <b>View Terms and Conditions</b>
              </Button>
              <br />

              {/* Terms and Conditions Modal */}
              <TermsAndConditionsModal
                show={showTermsModal}
                onHide={() => setShowTermsModal(false)}
                onChanged={handleTermsAccept}
              />

              {/* Alert for Terms Acceptance */}
              {!termsAccepted && (
                <Alert variant="danger">
                  Please read and acknowledge the terms and conditions by
                  ticking the checkbox at the bottom.
                </Alert>
              )}

              {/* Conditional Rendering Based on Data Availability */}
              <Table bordered>
                <thead>
                  <tr>
                    <th>Format</th>
                    <th>Size</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dataFormats.map((format) => (
                    <tr key={format.key}>
                      {/* Format Column */}
                      <td>{format.format}</td>

                      {/* Size Column */}
                      <td>{format.size}</td>

                      {/* Action Column */}
                      <td>
                        {format.available ? (
                          <>
                            {/* Download Button */}
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleDownload(
                                  format.key as "csv" | "json" | "pdf",
                                )
                              }
                              disabled={!termsAccepted || loading}
                              className="me-2"
                            >
                              Download {format.format}
                            </Button>

                            {/* Info Icon with Tooltip */}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-info-${format.key}`}>
                                  The {format.format} file contains sensitive
                                  data. Please handle it securely.
                                </Tooltip>
                              }
                            >
                              <Button variant="info" size="sm">
                                <i className="fas fa-info-circle"></i>
                              </Button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            {/* Request Data Generation Button */}
                            <Button
                              variant="secondary"
                              onClick={() =>
                                handleRequestData(
                                  format.key as "csv" | "json" | "pdf",
                                )
                              }
                              disabled={!termsAccepted || loading || isPolling}
                              className="me-2"
                            >
                              Request {format.format} Data Generation
                            </Button>

                            {/* Info Icon with Tooltip */}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-info-${format.key}`}>
                                  The {format.format} file needs to be generated
                                  upon request. This may take some time.
                                </Tooltip>
                              }
                            >
                              <Button variant="info" size="sm">
                                <i className="fas fa-info-circle"></i>
                              </Button>
                            </OverlayTrigger>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          /* No Data Available Message */
          <div className="text-center my-4">
            <i
              className="fas fa-exclamation-triangle mb-2"
              style={{ fontSize: "24px" }}
            ></i>
            <p className="font-weight-bold mb-1">
              No Data Available For Your Selection.
            </p>
            <p>Please contact support.</p>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default GetData;
