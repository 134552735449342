// src/hooks/useApiRequest.ts

import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useStore from "../store/store";
import { apiRequest } from "../utils/apiRequest";
import { API_BASE_URL } from "../config";
import useLogout from "./useLogout";
import {
  ApiRequestOptions,
  ApiCacheEntry,
  MakeRequestFunction,
} from "../types/apiRequest";

// Define cache duration as 60 minutes
const CACHE_DURATION = 60 * 60 * 1000; // in milliseconds

const useApiRequest = (): MakeRequestFunction => {
  // Extract auth data from the global store
  const { authData } = useStore((state) => state);
  const accessToken: string | undefined = authData?.access_token;
  const tokenExpiry: number | undefined = authData?.introspection?.exp; // UNIX timestamp

  const location = useLocation();
  const logout = useLogout();

  // Function to check if the token is expired
  const isTokenExpired = useCallback((): boolean => {
    if (!tokenExpiry || !accessToken) return true;
    const expiryTime = new Date(tokenExpiry * 1000);
    const isExpired = new Date() > expiryTime;
    return isExpired;
  }, [tokenExpiry, accessToken]);

  // Periodically check for token expiration every 1 minute
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (isTokenExpired()) {
        const currentPath = location.pathname;
        logout("error-expired-token", currentPath);
        console.log("Access token has expired, logging out...");
      }
    };

    const interval = setInterval(checkTokenExpiration, 1 * 60 * 1000); // 1 minute

    return () => clearInterval(interval);
  }, [isTokenExpired, location.pathname, logout]);

  // Function to generate a cache key based on the URL
  const getCacheKey = (url: string): string =>
    `apiCache_${encodeURIComponent(url)}`;

  // Function to retrieve cached response if valid
  const getCachedResponse = (cacheKey: string): Response | null => {
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      try {
        const parsed: ApiCacheEntry = JSON.parse(cached);
        if (Date.now() - parsed.timestamp < CACHE_DURATION) {
          // Cache is still valid
          const headers = new Headers(parsed.headers);
          const responseInit: ResponseInit = {
            status: parsed.status,
            statusText: parsed.statusText,
            headers,
          };
          return new Response(parsed.responseBody, responseInit);
        } else {
          // Cache has expired
          console.log(`Cache for ${cacheKey} has expired`);
          sessionStorage.removeItem(cacheKey);
        }
      } catch (error) {
        console.error("Error parsing cache entry:", error);
        sessionStorage.removeItem(cacheKey);
      }
    }
    return null;
  };

  // The main function to make API requests
  const makeRequest = useCallback(
    async ({
      url,
      method = "GET",
      body = null,
      additionalHeaders = {},
      needsToken = true,
      shouldCache = false,
    }: ApiRequestOptions): Promise<Response> => {
      // Determine the full URL
      let fullUrl = url;
      if (!url.startsWith("http")) {
        fullUrl = `${API_BASE_URL}${url}`;
      }

      // Generate cache key
      const cacheKey = getCacheKey(fullUrl);

      // If caching is enabled, attempt to retrieve from cache
      if (shouldCache) {
        const cachedResponse = getCachedResponse(cacheKey);
        if (cachedResponse) {
          console.log(`Using cached response for ${fullUrl}`);
          return cachedResponse;
        }
      }

      // Check if token is needed and valid
      if (needsToken && isTokenExpired()) {
        logout("error-expired-token", location.pathname);
        throw new Error("Access token is not available or has expired");
      }

      // Set up additional headers if needed
      const mergedHeaders = {
        ...additionalHeaders,
      };

      // If a token is needed and available, it should already be handled by `apiRequest`
      // Thus, we don't need to add Authorization header here

      // Prepare the API request options
      const apiRequestOptions: ApiRequestOptions = {
        url: fullUrl,
        method,
        body,
        additionalHeaders: mergedHeaders,
        needsToken,
        shouldCache,
      };

      try {
        // Use the `apiRequest` utility function to make the API call
        const response: Response = await apiRequest(
          apiRequestOptions.url,
          needsToken ? accessToken : undefined,
          apiRequestOptions.method,
          apiRequestOptions.body,
          apiRequestOptions.additionalHeaders,
        );

        // Handle unauthorized or forbidden responses
        if (
          (response.status === 401 || response.status === 403) &&
          location.pathname !== "/login"
        ) {
          logout("error-forbidden", "/login");
          throw new Error("Token has been revoked or is invalid");
        }

        // If caching is enabled and the response is successful, cache it
        if (shouldCache && response.ok) {
          const clonedResponse = response.clone();
          const responseBody = await clonedResponse.text();
          const cacheEntry: ApiCacheEntry = {
            responseBody,
            status: response.status,
            statusText: response.statusText,
            headers: Array.from(clonedResponse.headers.entries()),
            timestamp: Date.now(),
          };
          sessionStorage.setItem(cacheKey, JSON.stringify(cacheEntry));
        }

        return response;
      } catch (error) {
        console.error("Error making API request:", error);
        throw error;
      }
    },
    [
      accessToken,
      isTokenExpired,
      location.pathname,
      logout,
      API_BASE_URL, // Ensure API_BASE_URL is properly typed if needed
    ],
  );

  return makeRequest;
};

export default useApiRequest;
