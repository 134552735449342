// src/components/NewDataRequest/FieldSelectionModal.jsx

import React from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import PropTypes from "prop-types";

const FieldSelectionModal = ({
  show,
  handleClose,
  fieldsData,
  selectedFields,
  handleFieldSelection,
  selectAllFields,
  deselectAllFields,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Select Data Columns</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        {fieldsData.length === 0 ? (
          <Alert variant="warning">
            No fields available for the selected category.
          </Alert>
        ) : (
          <Form>
            <Form.Check
              type="checkbox"
              label="Select All"
              onChange={(e) => {
                if (e.target.checked) {
                  selectAllFields();
                } else {
                  deselectAllFields();
                }
              }}
              checked={
                selectedFields.length === fieldsData.length &&
                fieldsData.length > 0
              }
              className="mb-2"
              style={{
                fontWeight: "bold",
                color: "#347925",
              }} // Example style to make it distinguishable
            />
            {fieldsData.map((field, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                label={
                  <>
                    {field.label}
                    {field.defaultMasked && (
                      <span className="text-warning ms-2">
                        <i
                          className="bi bi-exclamation-triangle-fill"
                          title="This field is masked and contains sensitive data."
                        ></i>{" "}
                        Private
                      </span>
                    )}
                  </>
                }
                checked={selectedFields.some((f) => f.name === field.name)}
                onChange={() => handleFieldSelection(field)}
              />
            ))}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleClose}
          disabled={selectedFields.length === 0}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

FieldSelectionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fieldsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      inputType: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      hidden: PropTypes.bool.isRequired,
      masked: PropTypes.bool.isRequired,
      tooltip: PropTypes.string,
      options: PropTypes.array,
    })
  ).isRequired,
  selectedFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleFieldSelection: PropTypes.func.isRequired,
  selectAllFields: PropTypes.func.isRequired,
  deselectAllFields: PropTypes.func.isRequired,
};

export default FieldSelectionModal;
