// src/data/categories_mapping.js

import { getBioDataFields } from "./biodata_fields";
import { getCropFields } from "./crop_fields";
import { getLivestockFields } from "./livestock_fields";
import { getFisheriesFields } from "./fisheries_fields";
import livestockHierarchy from "./livestock_hierarchy.json";

export const categoriesMapping = {
  agriculture_value_chains_actors_registry: {
    table: "FarmerBioDataDetailsTbl",
    fields: getBioDataFields(),
    disabled: false,
  },
  crop_registry: {
    table: "FarmerCropDetailsTbl",
    fields: getCropFields(),
    disabled: false,
  },
  livestock_registry: {
    table: "FarmerLivestockDetailsTbl",
    fields: getLivestockFields(),
    disabled: false,
    hierarchy: livestockHierarchy,
  },
  fisheries_registry: {
    table: "FarmerAquacultureSpeciesDetailsTbl",
    fields: getFisheriesFields(),
    disabled: false,
  },
  farm_inputs_registry: {
    table: "FarmInputsTbl",
    fields: [],
    disabled: true,
  },
  farming_practices_registry: {
    table: "FarmingPracticesTbl",
    fields: [],
    disabled: true,
  },
  diseases_pests_registry: {
    table: "",
    fields: [],
    disabled: true,
  },
  market_pricing_registry: {
    table: "",
    fields: [],
    disabled: true,
  },
  natural_resources_registry: {
    table: "",
    fields: [],
    disabled: true,
  },
  storage_registry: {
    table: "",
    fields: [],
    disabled: true,
  },
  // Add more registries as needed
};
