// src/utils/countyMappings.js

import countiesAdministrativeMap from "../data/counties_administrative_map.json";

// Function to get County data
export const getCountyData = (countyName) => {
  return countiesAdministrativeMap.counties.find((c) => c.name === countyName);
};

// Function to get Subcounty data
export const getSubcountyData = (countyName, subcountyName) => {
  const county = getCountyData(countyName);
  return county
    ? county.subcounties.find((s) => s.name === subcountyName)
    : null;
};

// Function to get Ward data
export const getWardData = (countyName, subcountyName, wardName) => {
  const subcounty = getSubcountyData(countyName, subcountyName);
  return subcounty ? subcounty.wards.find((w) => w === wardName) : null;
};
