// src/utils/apiRequest.ts

export const apiRequest = async (
  url: string,
  accessToken?: string,
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" = "GET",
  body: FormData | object | string | null = null,
  additionalHeaders: Record<string, string> = {},
): Promise<Response> => {
  try {
    // Initialize headers with Authorization if accessToken is provided
    const headers: HeadersInit = {
      ...additionalHeaders,
    };

    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }

    // Do not explicitly set 'Content-Type' to 'application/json' if the body is FormData.
    // The browser will set the correct 'Content-Type' for FormData.
    if (!(body instanceof FormData) && body !== null) {
      headers["Content-Type"] = "application/json";
    }

    // Prepare the request configuration
    const config: RequestInit = {
      method,
      headers,
      body:
        method !== "GET" && body
          ? body instanceof FormData
            ? body
            : JSON.stringify(body)
          : null,
    };

    // Make the API request using Fetch API
    const response: Response = await fetch(url, config);

    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
