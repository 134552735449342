// src/data/crop_fields.js

export const getCropFields = () => [
  {
    name: "entry_id",
    label: "Entry ID",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Unique identifier for the crop entry.",
  },
  {
    name: "farmer_id",
    label: "Farmer ID",
    inputType: "text",
    hidden: false,
    masked: false,
    tooltip: "Unique identifier for the farmer.",
  },
  {
    name: "crop_name",
    label: "Crop Name",
    inputType: "text",
    hidden: false,
    masked: false,
    tooltip: "Name of the crop.",
  },
  {
    name: "crop_category",
    label: "Crop Category",
    inputType: "select",
    hidden: false,
    masked: false,
    options: ["Food Crops", "Cash Crops", "Fiber Crops", "Industrial Crops"],
    tooltip: "Category of the crop.",
  },
  {
    name: "acreage",
    label: "Acreage",
    inputType: "number",
    hidden: false,
    masked: false,
    tooltip: "Amount of land (in acres) used for the crop.",
  },
  {
    name: "unit_of_area",
    label: "Unit of Area",
    inputType: "select",
    hidden: false,
    masked: false,
    options: ["Acre"],
    tooltip: "Unit of measurement for acreage.",
  },
  {
    name: "use_of_certified_seeds",
    label: "Use of Certified Seeds",
    inputType: "radio",
    hidden: false,
    masked: false,
    options: [
      { label: "Yes", value: "1" },
      { label: "No", value: "0" },
    ],
    tooltip: "Whether certified seeds are used.",
  },
  {
    name: "purpose",
    label: "Purpose",
    inputType: "select",
    hidden: false,
    masked: false,
    options: ["Commercial", "Subsistence", "Both"],
    tooltip: "Purpose of the crop cultivation.",
  },
  {
    name: "production_system",
    label: "Production System",
    inputType: "select",
    hidden: false,
    masked: false,
    options: [
      "Mono-cropping",
      "Mixed farming(intercropping)",
      "Agroforestry",
      "Plantations",
      "Greenhouses",
    ],
    tooltip: "Production system used for cultivation.",
  },
  {
    name: "water_source",
    label: "Water Source",
    inputType: "select",
    hidden: false,
    masked: false,
    options: ["Irrigated", "Rainfed", "Both"],
    tooltip: "Source of water for cultivation.",
  },
  {
    name: "use_of_pesticide",
    label: "Use of Pesticide",
    inputType: "radio",
    hidden: false,
    masked: false,
    options: [
      { label: "Yes", value: "1" },
      { label: "No", value: "0" },
    ],
    tooltip: "Whether pesticides are used.",
  },
  {
    name: "fungicides",
    label: "Fungicides",
    inputType: "radio",
    hidden: false,
    masked: false,
    options: [
      { label: "Yes", value: "1" },
      { label: "No", value: "0" },
    ],
    tooltip: "Whether fungicides are used.",
  },
  {
    name: "insecticides",
    label: "Insecticides",
    inputType: "radio",
    hidden: false,
    masked: false,
    options: [
      { label: "Yes", value: "1" },
      { label: "No", value: "0" },
    ],
    tooltip: "Whether insecticides are used.",
  },
  {
    name: "herbicides",
    label: "Herbicides",
    inputType: "radio",
    hidden: false,
    masked: false,
    options: [
      { label: "Yes", value: "1" },
      { label: "No", value: "0" },
    ],
    tooltip: "Whether herbicides are used.",
  },
  {
    name: "rodenticides",
    label: "Rodenticides",
    inputType: "radio",
    hidden: false,
    masked: false,
    options: [
      { label: "Yes", value: "1" },
      { label: "No", value: "0" },
    ],
    tooltip: "Whether rodenticides are used.",
  },
  {
    name: "molluscides",
    label: "Molluscides",
    inputType: "radio",
    hidden: false,
    masked: false,
    options: [
      { label: "Yes", value: "1" },
      { label: "No", value: "0" },
    ],
    tooltip: "Whether molluscides are used.",
  },
];
