// src/utils/livestockMappings.js

import livestockHierarchy from "../data/livestock_hierarchy.json";

// Function to get Livestock Category Data
export const getLivestockCategoryData = (categoryName) => {
  return livestockHierarchy.livestock_categories.find(
    (c) => c.category === categoryName
  );
};

// Function to get Livestock Subcategory Data
export const getLivestockSubcategoryData = (categoryName, subcategoryName) => {
  const category = getLivestockCategoryData(categoryName);
  return category
    ? category.subcategories.find((s) => s.subcategory === subcategoryName)
    : null;
};

// Function to get Livestock Names
export const getLivestockNames = (categoryName, subcategoryName) => {
  const subcategory = getLivestockSubcategoryData(
    categoryName,
    subcategoryName
  );
  return subcategory ? subcategory.livestock_names : [];
};
