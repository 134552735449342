// src/components/Admin/AdminDataRequestsTable.tsx

import React from "react";
import { Table, Button, Badge, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import CopyButton from "../CopyButton";
import { getCountyName } from "../../utils/getCountiesFromCodes";
import { useFetchCategories } from "../../hooks/dataHooks";
import { AdminDataRequestsTableProps } from "../../types/components";
import { CategoryOption } from "../../types/category";
import { DataRequest } from "../../types/dataRequest";

const AdminDataRequestsTable: React.FC<AdminDataRequestsTableProps> = ({
  dataRequests,
  handlerReview,
  loading,
  handlerDSA,
  isSuperAdmin,
  endpoint,
  getCategoryLabel,
}) => {
  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Request ID</th>
          <th>Applicant</th>
          <th>Organization</th>
          <th>Counties</th>
          <th>Dates</th>
          <th>Fields</th>
          <th>Reason</th>
          <th>Data Agreement</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {dataRequests.map((request: DataRequest) => {
          const canReview = isSuperAdmin
            ? request.status === "made" || request.status === "checker-review"
            : request.status === "incoming" ||
              request.status === "maker-review";

          const buttonVariant = canReview ? "success" : "secondary";

          const buttonText = canReview
            ? "Review"
            : endpoint === "approved-checked"
              ? "Approved"
              : endpoint === "rejected-checked"
                ? "Rejected"
                : "Cannot Review";
          // Safely find the category label

          return (
            <tr key={request.id}>
              <td>
                <small>{request.id}</small>
                <br />
                <CopyButton text={request.id} />
              </td>
              <td>
                <small>
                  {request.firstName} {request.lastName}
                </small>
              </td>
              <td>
                <small>{request.organization}</small>
              </td>

              <td>
                <span className="small">
                  {request.counties &&
                    request.counties.length > 0 &&
                    request.counties.join(", ")}
                </span>
              </td>
              <td>
                <div>
                  <Badge bg="info">Request Date</Badge>
                  <br />
                  <small className="small">
                    {new Date(request.serverTimestamp).toLocaleDateString()},{" "}
                    {new Date(request.serverTimestamp).toLocaleTimeString([], {
                      hour: "numeric",
                      minute: "2-digit",
                    })}
                  </small>
                </div>
                <div style={{ marginTop: "10px" }}>
                  {request.datetimeOfResponse !== null && (
                    <div>
                      <Badge
                        bg={
                          request.status === "made"
                            ? "warning"
                            : request.status === "checked"
                              ? "success"
                              : request.status === "rejected"
                                ? "danger"
                                : "info"
                        }
                      >
                        Response Date
                      </Badge>
                      <br />
                      <small className="small">
                        {new Date(
                          request.datetimeOfResponse,
                        ).toLocaleDateString()}
                        ,{" "}
                        {new Date(
                          request.datetimeOfResponse,
                        ).toLocaleTimeString([], {
                          hour: "numeric",
                          minute: "2-digit",
                        })}
                      </small>
                    </div>
                  )}
                </div>
                {request.adminMaker.Valid && (
                  <>
                    <Badge
                      bg={
                        request.makerRecommendationInitial.String === "approve"
                          ? "success"
                          : request.makerRecommendationInitial.String ===
                              "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Initiated by:
                    </Badge>
                    <br />
                    <small>{request.adminMaker.String}</small>
                    <div>
                      <Badge bg="info">Request Date</Badge>
                      <br />
                      <small className="small">
                        {new Date(request.serverTimestamp).toLocaleDateString()}
                        ,{" "}
                        {new Date(request.serverTimestamp).toLocaleTimeString(
                          [],
                          {
                            hour: "numeric",
                            minute: "2-digit",
                          },
                        )}
                      </small>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      {request.datetimeOfResponse !== null && (
                        <div>
                          <Badge
                            bg={
                              request.status === "made"
                                ? "warning"
                                : request.status === "checked"
                                  ? "success"
                                  : request.status === "rejected"
                                    ? "warning"
                                    : "info"
                            }
                          >
                            Response Date
                          </Badge>
                          <br />
                          <small>
                            {new Date(
                              request.datetimeOfResponse,
                            ).toLocaleDateString()}
                            ,{" "}
                            {new Date(
                              request.datetimeOfResponse,
                            ).toLocaleTimeString([], {
                              hour: "numeric",
                              minute: "2-digit",
                            })}
                          </small>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {request.makerRecommendationInitial.Valid && (
                  <>
                    <Badge
                      bg={
                        request.makerRecommendationInitial.String === "approve"
                          ? "success"
                          : request.makerRecommendationInitial.String ===
                              "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Initiator recommendation:
                    </Badge>
                    <br />
                    <small>{request.makerRecommendationInitial.String}</small>
                  </>
                )}
                {request.adminChecker.Valid && (
                  <>
                    <br />
                    <Badge
                      bg={
                        request.checkerRecommendationInitial.String ===
                        "approve"
                          ? "success"
                          : request.checkerRecommendationInitial.String ===
                              "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Approved by:
                    </Badge>
                    <br />
                    <small>{request.adminChecker.String}</small>
                  </>
                )}
                {request.checkerRecommendationInitial.Valid && (
                  <>
                    <Badge
                      bg={
                        request.checkerRecommendationInitial.String ===
                        "approve"
                          ? "success"
                          : request.checkerRecommendationInitial.String ===
                              "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Approver recommendation:
                    </Badge>
                    <br />
                    <small>{request.checkerRecommendationInitial.String}</small>
                    <br />
                  </>
                )}

                {request.makerRecommendationReview.Valid && (
                  <>
                    <Badge
                      bg={
                        request.makerRecommendationReview.String === "approve"
                          ? "success"
                          : request.makerRecommendationReview.String ===
                              "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Initiator review recommendation:
                    </Badge>
                    <br />
                    <small>{request.makerRecommendationReview.String}</small>
                    <br />
                  </>
                )}

                {request.checkerRecommendationReview.Valid && (
                  <>
                    <Badge
                      bg={
                        request.checkerRecommendationReview.String === "approve"
                          ? "success"
                          : request.checkerRecommendationReview.String ===
                              "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Approver review recommendation:
                    </Badge>
                    <br />
                    <small>{request.checkerRecommendationReview.String}</small>
                    <br />
                  </>
                )}
              </td>
              <td>
                {request.registries.map((reg, i) => (
                  <div key={i} className="mb-2">
                    <Badge bg="success">
                      {getCategoryLabel(reg.categoryId)}
                    </Badge>
                    <ul>
                      {reg.fields.map((field, i2) => (
                        <li key={i2}>
                          <span className="small">{field.alias}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </td>
              <td>{request.reason}</td>
              <td>
                {request.dsa &&
                request.dsa !== "" &&
                request.dsa !== "county" ? (
                  <>
                    <Badge bg="success">Attached</Badge>
                    <Button
                      style={{
                        background: "none",
                        borderColor: "transparent",
                        color: "#347925",
                        textDecoration: "underline",
                      }}
                      onClick={() => handlerDSA(request.id)}
                    >
                      View
                      <FontAwesomeIcon
                        className="ms-2"
                        icon={faExternalLinkAlt}
                        size="xs"
                      />
                    </Button>
                  </>
                ) : request.dsa === "county" ? (
                  <Badge bg="success">County Government</Badge>
                ) : (
                  <Badge bg="danger">Missing</Badge>
                )}
              </td>
              <td>
                <Button
                  variant={buttonVariant}
                  onClick={() => handlerReview(request)}
                  disabled={!canReview}
                >
                  {buttonText}
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default AdminDataRequestsTable;
