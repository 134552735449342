// src/pages/approved-user/UserDataRequests.tsx

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "chart.js/auto";
import {
  Badge,
  Button,
  Card,
  Pagination,
  Spinner,
  Table,
  Alert,
} from "react-bootstrap";
import "reactjs-popup/dist/index.css";
import CopyButton from "../../components/CopyButton";
import useApiRequest from "../../hooks/useApiRequest";
import { getCountyName } from "../../utils/getCountiesFromCodes";
import { DataRequest } from "../../types/dataRequest";

const UserDataRequests: React.FC = () => {
  // State variables with type annotations
  const [data, setData] = useState<DataRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [flashMessage, setFlashMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const apiRequest = useApiRequest();

  // Define the number of items per page
  const itemsPerPage: number = 10; // Adjust this based on your preference

  // Handler for navigating to GetData component with dataRequest
  const handleGetDataClick = (dataRequest: DataRequest) => {
    navigate(`/u/data-requests/${dataRequest.id}`, { state: { dataRequest } });
  };

  // Utility function to capitalize the first letter
  const capitalizeFirstLetter = (str: string): string => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Fetch data when component mounts or when currentPage changes
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await apiRequest({
          url: `/data-requests/user-requests?page=${currentPage}&limit=${itemsPerPage}`,
          method: "GET",
        });

        if (response.ok) {
          const jsonData = await response.json();
          if (jsonData) {
            setData(jsonData.dataRequests);
            setTotalPages(jsonData.totalPages);
          } else {
            console.error("Error: Received null or undefined response data");
            setFlashMessage("Received invalid data from the server.");
          }
        } else {
          console.error("Error fetching data:", response.statusText);
          setFlashMessage("Error fetching data. Please try again later.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setFlashMessage("Error fetching data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiRequest, currentPage, itemsPerPage]);

  // Handler for page changes
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // Function to dynamically generate table headers based on data keys
  const generateTableHeaders = (data: DataRequest[]): string[] => {
    if (!data || data.length === 0) return [];
    // Extract unique headers from all dataRequests
    const headersSet = new Set<string>();
    data.forEach((request) => {
      headersSet.add("ID");
      headersSet.add("Counties");
      headersSet.add("Date");
      headersSet.add("Topics");
      headersSet.add("Status");
      headersSet.add("Data Access");
    });
    return Array.from(headersSet);
  };

  // Dynamically render table rows and cells
  const renderTableRows = (data: DataRequest[]): JSX.Element[] => {
    return data.map((request) => (
      <tr key={request.id}>
        {/* ID */}
        <td width={200}>
          <span className="small">{request.id}</span>
          <br />
          <CopyButton text={request.id} />
        </td>

        {/* Counties */}
        <td>
          <span className="small">
            {request.counties && request.counties.length > 0
              ? request.counties.join(", ")
              : "N/A"}
          </span>
        </td>

        {/* Date */}
        <td>
          <div>
            <Badge bg="info">Request Date</Badge>
            <br />
            <small className="small">
              {new Date(request.serverTimestamp).toLocaleString()}
            </small>
          </div>
          {request.datetimeOfResponse && (
            <div style={{ marginTop: "10px" }}>
              <Badge
                bg={
                  request.status === "made"
                    ? "warning"
                    : request.status === "checked"
                    ? "success"
                    : request.status === "rejected"
                    ? "danger"
                    : "info"
                }
              >
                Response Date
              </Badge>
              <br />
              <small className="small">
                {new Date(request.datetimeOfResponse).toLocaleString()}
              </small>
            </div>
          )}
          {request.adminMaker.Valid && (
            <>
              <Badge bg="warning" className="mt-2">
                Verifier:
              </Badge>
              <br />
              <small>{request.adminMaker.String}</small>
              <br />
            </>
          )}
          {request.adminChecker.Valid && (
            <>
              <Badge bg="warning" className="mt-2">
                Approver:
              </Badge>
              <br />
              <small>{request.adminChecker.String}</small>
            </>
          )}
        </td>

        {/* Topics */}
        <td>
          {request.registries.map((reg, i) => (
            <div key={i} className="mb-2">
              <strong>
                <Badge bg="success" className="mb-1">
                  {reg.categoryId}
                </Badge>
              </strong>
              <ul>
                {reg.fields.map((field, i2) => (
                  <li key={i2}>
                    <span className="small">{field.alias}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </td>

        {/* Status */}
        <td>
          <Badge
            bg={
              request.status === "checked"
                ? "primary"
                : request.status === "made"
                ? "warning"
                : request.status === "rejected"
                ? "danger"
                : "info"
            }
          >
            {capitalizeFirstLetter(
              request.status === "incoming"
                ? "Pending verification"
                : request.status === "made" ||
                  request.status === "maker-review" ||
                  request.status === "checker-review"
                ? "Pending approval"
                : request.status === "checked"
                ? "Approved"
                : request.status === "rejected"
                ? "Rejected"
                : request.status === "impasse"
                ? "Impasse"
                : request.status
            )}
          </Badge>
        </td>

        {/* Data Access */}
        <td>
          {request.status === "checked" ? (
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleGetDataClick(request)}
            >
              <strong> Access Data</strong>
            </Button>
          ) : (
            <Button variant="danger" size="sm" disabled>
              Inactive
            </Button>
          )}

          {/* Spacer */}
          <div style={{ marginTop: "8px" }}>
            {/* Info Pills for Available Files */}
            {request.csvAvailable && (
              <Badge bg="info" className="me-1">
                CSV Available
              </Badge>
            )}
            {request.jsonAvailable && (
              <Badge bg="info" className="me-1">
                JSON Available
              </Badge>
            )}
            {request.pdfAvailable && (
              <Badge bg="info" text="dark">
                PDF Available
              </Badge>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  // Function to render pagination items
  const renderPagination = (): JSX.Element[] => {
    const items: JSX.Element[] = [];
    const active = currentPage;
    const itemsCount = totalPages;

    if (itemsCount <= 10) {
      for (let number = 1; number <= itemsCount; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      items.push(
        <Pagination.Item
          key="1"
          active={1 === active}
          onClick={() => handlePageChange(1)}
        >
          1
        </Pagination.Item>
      );
      items.push(
        <Pagination.Item
          key="2"
          active={2 === active}
          onClick={() => handlePageChange(2)}
        >
          2
        </Pagination.Item>
      );

      // Determine start and end pages
      let startPage = active > 4 ? active - 2 : 3;
      let endPage = active + 2 < itemsCount - 1 ? active + 2 : itemsCount - 2;

      if (startPage > 3) {
        items.push(<Pagination.Ellipsis key="startEllipsis" />);
      }

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }

      if (endPage < itemsCount - 2) {
        items.push(<Pagination.Ellipsis key="endEllipsis" />);
      }

      items.push(
        <Pagination.Item
          key={itemsCount - 1}
          active={itemsCount - 1 === active}
          onClick={() => handlePageChange(itemsCount - 1)}
        >
          {itemsCount - 1}
        </Pagination.Item>
      );
      items.push(
        <Pagination.Item
          key={itemsCount}
          active={itemsCount === active}
          onClick={() => handlePageChange(itemsCount)}
        >
          {itemsCount}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Card className="mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold">My Data Requests</h6>
      </Card.Header>
      <Card.Body>
        {/* Flash Message */}
        {flashMessage && (
          <Alert
            variant="info"
            onClose={() => setFlashMessage(null)}
            dismissible
          >
            {flashMessage}
          </Alert>
        )}

        {/* Loading Spinner */}
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <h6>Request Details</h6>
            <Table hover bordered responsive>
              <thead>
                <tr>
                  {generateTableHeaders(data).map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{renderTableRows(data)}</tbody>
            </Table>

            {/* Pagination */}
            <div>
              <Pagination>
                <Pagination.First
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />

                {renderPagination()}

                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                <Pagination.Last
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default UserDataRequests;
