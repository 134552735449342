// src/components/Admin/AdminDataRequests.tsx

import React, { useState, useEffect } from "react";
import { Card, Spinner, Pagination } from "react-bootstrap";
import toast from "react-hot-toast";
import useApiRequest from "../../hooks/useApiRequest";
import AdminDataRequestsTable from "../../components/Admin/AdminDataRequestsTable";
import DataRequestModal from "../../components/Admin/DataRequestModal";
import useStore from "../../store/store";
import { DataRequest, RejectReason } from "../../types/dataRequest";
import { AdminDataRequestsProps } from "../../types/components";
import { useFetchCategories } from "../../hooks/dataHooks";
import { Category, CategoryOption } from "../../types/category";

const AdminDataRequests: React.FC<AdminDataRequestsProps> = ({ endpoint }) => {
  const [dataRequests, setDataRequests] = useState<DataRequest[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRequest, setSelectedRequest] = useState<DataRequest | null>(
    null,
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const [dsaReviewed, setDsaReviewed] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState<string>("");
  const [feedbackInitial, setFeedbackInitial] = useState<string>("");
  const [rejectionReasons, setRejectionReasons] = useState<RejectReason[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { categoryOptions, fetchCategories } = useFetchCategories();

  const getCategoryLabel = (registry: string): string =>
    categoryOptions.find((c: CategoryOption) => c.value === registry)?.label ||
    registry;

  const makeRequest = useApiRequest();

  const { isSuperAdmin } = useStore((state) => ({
    isSuperAdmin: state.isSuperAdmin,
  }));

  // If endpoint changes, reset the page to 1
  useEffect(() => {
    setCurrentPage(1);
  }, [endpoint]);

  useEffect(() => {
    if (selectedRequest && selectedRequest.tenantType === "county") {
      setDsaReviewed(true);
    }
  }, [selectedRequest]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await makeRequest({
          url: `/data-requests/${endpoint}?limit=10&page=${currentPage}`,
          method: "GET",
          needsToken: true,
          shouldCache: false,
        });

        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const jsonData = await response.json();
        setDataRequests(jsonData.dataRequests);
        setTotalPages(jsonData.totalPages);
        setTotalItems(jsonData.totalCount);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data requests.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [makeRequest, currentPage, endpoint]);

  useEffect(() => {
    const fetchRejectionReasons = async () => {
      try {
        const response = await makeRequest({
          url: `/hive/rejection-reasons`,
          method: "GET",
          needsToken: true,
          shouldCache: false,
        });
        if (!response.ok) {
          throw new Error(
            `Error fetching rejection reasons: ${response.statusText}`,
          );
        }
        const data: RejectReason[] = await response.json();
        setRejectionReasons(data);
      } catch (error) {
        console.error("Failed to fetch rejection reasons:", error);
        toast.error("Failed to fetch rejection reasons.");
      }
    };
    fetchRejectionReasons();
  }, [makeRequest]);

  // Handlers
  const handlePageChange = (newPage: number) => setCurrentPage(newPage);

  const handleRejectReasonChange = (reason: string) => {
    setRejectReason(reason);
  };

  const handleCheckboxChange = () => setDsaReviewed(!dsaReviewed);

  const handleShowModal = (request: DataRequest) => {
    setSelectedRequest(request); // Load the request data into state
    setDsaReviewed(false);
    setRejectReason(request.makerRejectReasonInitial.String || "");
    setFeedbackInitial("");
    setShowModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRequest(null); // Reset selected request on modal close
  };

  /**
   * Handler to approve or reject a data request.
   */
  const handleDataRequestApprove = async (
    recommendation: "approve" | "reject",
  ): Promise<void> => {
    if (isSubmitting || !selectedRequest) return;
    try {
      const requestId: string = selectedRequest.id;

      // Extract all fields from all registries
      const registries = selectedRequest.registries || [];
      const fieldsJson: string = JSON.stringify(registries);

      let status: string = selectedRequest.status;

      // Update status based on existing recommendations
      if (
        selectedRequest.makerRecommendationInitial &&
        !selectedRequest.checkerRecommendationInitial
      ) {
        status = "made";
      } else if (selectedRequest.checkerRecommendationInitial) {
        if (
          selectedRequest.checkerRecommendationInitial ===
          selectedRequest.makerRecommendationInitial
        ) {
          status = "checked";
        } else {
          status = "maker-review";
        }
      } else if (selectedRequest.makerRecommendationReview) {
        status = "checker-review";
      } else if (selectedRequest.checkerRecommendationReview) {
        if (
          selectedRequest.checkerRecommendationReview ===
          selectedRequest.makerRecommendationReview
        ) {
          status = "checked";
        } else {
          status = "impasse";
        }
      }

      const rReason: string =
        selectedRequest?.dsa?.trim() === "" ? "no_dsa" : rejectReason;

      // Create form data
      const formData = new FormData();
      formData.append("registries", fieldsJson); // Updated to send registries
      formData.append("recommendation", recommendation);
      if (recommendation === "reject") {
        formData.append("rejectReason", rReason);
        formData.append("feedback", feedbackInitial);
      }

      setIsSubmitting(true);

      let url: string = "";
      console.log("isSuperadmin", isSuperAdmin);
      console.log("selectedRequest.status", selectedRequest.status);

      switch (true) {
        case !isSuperAdmin && selectedRequest.status === "incoming":
          url = `/data-requests/approve-make/${requestId}`;
          break;
        case !isSuperAdmin && selectedRequest.status === "maker-review":
          url = `/data-requests/approve-make-review/${requestId}`;
          break;
        case isSuperAdmin && selectedRequest.status === "made":
          url = `/data-requests/approve-check/${requestId}`;
          break;
        case isSuperAdmin && selectedRequest.status === "checker-review":
          url = `/data-requests/approve-check-review/${requestId}`;
          break;

        default:
          break;
      }

      if (url === "") {
        console.error("There was an error generating the approve URL");
        toast.error("There was an error generating the approve URL");
        setIsSubmitting(false);
        return;
      }

      const res: Response = await makeRequest({
        url,
        method: "POST",
        body: formData,
        needsToken: true,
        shouldCache: false,
      });

      setIsSubmitting(false);

      if (!res.ok) {
        if (recommendation === "approve") {
          toast.error("There was an error approving this request.");
        } else {
          toast.error("There was an error rejecting this request.");
        }
        return;
      }

      // Update dataRequests state based on recommendation
      if (recommendation === "approve") {
        toast.success("Data request approved successfully.");
        const newData: DataRequest[] = dataRequests.map((request) =>
          request.id === requestId
            ? {
                ...request,
                status: "checked",
                datetimeOfResponse: new Date(),
              }
            : request,
        );
        setDataRequests(newData);
      } else {
        toast.success("Data request rejected successfully.");
        const newData: DataRequest[] = dataRequests.map((request) =>
          request.id === requestId
            ? {
                ...request,
                status: "rejected",
                datetimeOfResponse: new Date(),
              }
            : request,
        );
        setDataRequests(newData);
        setRejectReason("");
      }
      setShowModal(false);
    } catch (error) {
      console.error(
        "An error occurred while approving/rejecting data request:",
        error,
      );
      toast.error("Error processing the data request.");
      throw error;
    }
  };

  /**
   * Handler to open the modal for reviewing a request.
   */
  const handlerReview = (request: DataRequest): void => {
    handleShowModal(request);
  };

  /**
   * Handler to download DSA.
   */
  const handlerDSA = async (incomingId: string): Promise<void> => {
    try {
      const requestId = incomingId || selectedRequest?.id;
      if (!requestId) {
        console.log("Request ID is empty.");
        return;
      }
      const response = await makeRequest({
        url: `/data-requests/download-dsa/${incomingId ?? requestId}`,
        method: "GET",
        needsToken: true,
        shouldCache: false,
      });

      if (!response.ok) {
        throw new Error(`Failed to download DSA: ${response.statusText}`);
      }

      const pdfBlob = await response.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("An error occurred while downloading DSA:", error);
      toast.error("Failed to download DSA.");
    }
  };

  /**
   * Handler to toggle masked data.
   */
  const toggleMaskData = (categoryId: string, fieldTitle: string): void => {
    setSelectedRequest((currentSelectedRequest) => {
      if (!currentSelectedRequest) return null;

      // Iterate through registries to find the matching categoryId
      const updatedRegistries = currentSelectedRequest.registries.map(
        (registry) => {
          if (registry.categoryId !== categoryId) {
            // If the registry doesn't match, return it unchanged
            return registry;
          }

          // Iterate through fields to find the matching fieldTitle (name or alias)
          const updatedFields = registry.fields.map((field) => {
            if (field.name === fieldTitle || field.alias === fieldTitle) {
              // Toggle the 'masked' property
              return { ...field, masked: !field.masked };
            }
            return field;
          });

          // Return the updated registry with modified fields
          return { ...registry, fields: updatedFields };
        },
      );

      // Return the updated selectedRequest with modified registries
      return { ...currentSelectedRequest, registries: updatedRegistries };
    });
  };

  /**
   * Function to get the title based on the endpoint.
   */
  const getTitle = (): string => {
    switch (endpoint) {
      case "incoming":
        return "Incoming Data Access Requests (Not yet approved)";
      case "maker-review":
        return "Maker Review Data Access Requests";
      case "checker-review":
        return "Checker Review Data Access Requests";
      case "made":
        return "Partially Approved Data Access Requests";
      case "approved-checked":
        return "Fully Approved Data Access Requests";
      case "rejected-checked":
        return "Rejected Data Access Requests";
      case "impasse":
        return "Impasse Data Access Requests";
      case "all":
      default:
        return "All Data Access Requests";
    }
  };

  /**
   * Function to render pagination items.
   */
  const renderPagination = () => {
    const items = [];
    const active = currentPage;
    const pagesCount = totalPages;

    if (pagesCount <= 10) {
      for (let number = 1; number <= pagesCount; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>,
        );
      }
    } else {
      items.push(
        <Pagination.Item
          key="1"
          active={1 === active}
          onClick={() => handlePageChange(1)}
        >
          1
        </Pagination.Item>,
      );
      items.push(
        <Pagination.Item
          key="2"
          active={2 === active}
          onClick={() => handlePageChange(2)}
        >
          2
        </Pagination.Item>,
      );

      // Dynamically add the current, previous, and next two pages if not near the start or end
      const startPage = active > 4 ? active - 2 : 3;
      const endPage = active + 2 < pagesCount - 1 ? active + 2 : pagesCount - 2;

      if (startPage > 3) {
        items.push(<Pagination.Ellipsis key="startEllipsis" />);
      }

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>,
        );
      }

      if (endPage < pagesCount - 2) {
        items.push(<Pagination.Ellipsis key="endEllipsis" />);
      }

      items.push(
        <Pagination.Item
          key={pagesCount - 1}
          active={pagesCount - 1 === active}
          onClick={() => handlePageChange(pagesCount - 1)}
        >
          {pagesCount - 1}
        </Pagination.Item>,
      );
      items.push(
        <Pagination.Item
          key={pagesCount}
          active={pagesCount === active}
          onClick={() => handlePageChange(pagesCount)}
        >
          {pagesCount}
        </Pagination.Item>,
      );
    }

    return items;
  };

  return (
    <Card className="shadow mb-4">
      <Card.Header className="d-flex flex-row align-items-center justify-content-between">
        <h6>{getTitle()}</h6>
      </Card.Header>
      <Card.Body>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : totalItems === 0 ? (
          <div className="text-center">
            <p>There are no data requests for this tab.</p>
          </div>
        ) : (
          <>
            <AdminDataRequestsTable
              dataRequests={dataRequests}
              handlerReview={handlerReview}
              loading={loading}
              handlerDSA={handlerDSA}
              isSuperAdmin={isSuperAdmin}
              endpoint={endpoint}
              getCategoryLabel={getCategoryLabel}
            />

            <Pagination className="justify-content-center my-4">
              <Pagination.First
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {renderPagination()}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </>
        )}

        {selectedRequest && (
          <DataRequestModal
            showModal={showModal}
            handleClose={handleCloseModal}
            selectedRequest={selectedRequest}
            onApprove={handleDataRequestApprove}
            toggleMaskData={toggleMaskData}
            handleRejectReasonChange={handleRejectReasonChange}
            rejectReason={rejectReason}
            rejectionReasons={rejectionReasons}
            dsaReviewed={dsaReviewed}
            handleCheckboxChange={handleCheckboxChange}
            handlerDSA={handlerDSA}
            isSubmitting={isSubmitting}
            isSuperAdmin={isSuperAdmin}
            feedbackInitial={feedbackInitial}
            handleFeedbackInitialChange={setFeedbackInitial}
            getCategoryLabel={getCategoryLabel}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default AdminDataRequests;
