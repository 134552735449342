// src/components/NewDataRequest/Rule.jsx

import React, { useEffect, useState } from "react";
import { Form, Button, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css"; // Ensure Bootstrap Icons are imported
import PropTypes from "prop-types";

const Rule = ({
  rule,
  groupId,
  fieldsData,
  handleFieldChange,
  handleOperatorChange,
  handleValueChange,
  handleValue2Change,
  removeRule,
  getFieldsForRule,
  groupRules,
  getCountyData,
  getSubcountyData,
  getLivestockCategoryData,
  getLivestockSubcategoryData,
  isCountyAccount, // New prop
}) => {
  // Filter out the "county" field if the user is a county account
  const availableFields = isCountyAccount
    ? fieldsData.filter((field) => field.name !== "county")
    : fieldsData;

  const fieldDef = getFieldsForRule(rule);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (fieldDef.masked) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [fieldDef]);

  // Determine if the subcounty should be disabled
  let isSubcountyDisabled = false;
  let subcountyOptions = [];
  let isWardDisabled = false;
  let wardOptions = [];

  if (rule.field === "subcounty") {
    const countyRule = groupRules.find((r) => r.field === "county");
    const selectedCounty = countyRule ? countyRule.value : "";
    isSubcountyDisabled = !selectedCounty;
    if (selectedCounty) {
      const county = getCountyData(selectedCounty);
      subcountyOptions = county
        ? county.subcounties.map((sub) => sub.name)
        : [];
    }
  }

  if (rule.field === "ward") {
    const subcountyRule = groupRules.find((r) => r.field === "subcounty");
    const selectedSubcounty = subcountyRule ? subcountyRule.value : "";
    isWardDisabled = !selectedSubcounty;
    if (selectedSubcounty) {
      const countyRule = groupRules.find((r) => r.field === "county");
      const selectedCounty = countyRule ? countyRule.value : "";
      const subcounty = getSubcountyData(selectedCounty, selectedSubcounty);
      wardOptions = subcounty ? subcounty.wards : [];
    }
  }

  // Determine if the livestock subcategory should be disabled
  let isLivestockSubcategoryDisabled = false;
  let livestockSubcategoryOptions = [];
  let isLivestockNameDisabled = false;
  let livestockNameOptions = [];

  if (rule.field === "livestock_sub_category") {
    const categoryRule = groupRules.find(
      (r) => r.field === "livestock_category"
    );
    const selectedCategory = categoryRule ? categoryRule.value : "";
    isLivestockSubcategoryDisabled = !selectedCategory;
    if (selectedCategory) {
      const subcategoryData = getLivestockCategoryData(selectedCategory);
      livestockSubcategoryOptions = subcategoryData
        ? subcategoryData.subcategories.map((s) => s.subcategory)
        : [];
    }
  }

  if (rule.field === "livestock_name") {
    const subcategoryRule = groupRules.find(
      (r) => r.field === "livestock_sub_category"
    );
    const selectedSubcategory = subcategoryRule ? subcategoryRule.value : "";
    isLivestockNameDisabled = !selectedSubcategory;
    if (selectedSubcategory) {
      const categoryRule = groupRules.find(
        (r) => r.field === "livestock_category"
      );
      const selectedCategory = categoryRule ? categoryRule.value : "";
      const subcategory = getLivestockSubcategoryData(
        selectedCategory,
        selectedSubcategory
      );
      livestockNameOptions = subcategory ? subcategory.livestock_names : [];
    }
  }

  // Define operators outside the component or import from a constants file
  const operators = [
    { label: "is equal to (=)", value: "=" },
    { label: "is not equal to (!=)", value: "!=" },
    { label: "is greater than (>)", value: ">" },
    { label: "is less than (<)", value: "<" },
    { label: "is greater than or equal to (>=)", value: ">=" },
    { label: "is less than or equal to (<=)", value: "<=" },
    { label: "contains (LIKE)", value: "LIKE" },
    { label: "is between", value: "BETWEEN" },
    { label: "is before", value: "BEFORE" },
    { label: "is after", value: "AFTER" },
    { label: "is null", value: "IS NULL" },
    { label: "is not null", value: "IS NOT NULL" },
  ];

  return (
    <>
      {/* Auto-Added Rule Indicator */}
      {rule.isAuto && (
        <div className="d-flex align-items-center mt-1">
          <Badge bg="info" className="me-2">
            Auto-Added
          </Badge>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-info-${rule.id}`}>
                This rule cannot be changed because of your account type.
              </Tooltip>
            }
          >
            <i
              className="bi bi-info-circle-fill text-secondary"
              style={{ cursor: "pointer" }}
              aria-label="Information"
              role="img"
            ></i>
          </OverlayTrigger>
        </div>
      )}
      <div className="row mb-3 align-items-center">
        {/* Field Selector */}
        <div className="col-md-3">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-field-${rule.id}`}>
                {fieldDef.tooltip || "Select a field to filter by."}
              </Tooltip>
            }
          >
            <Form.Select
              value={rule.field}
              onChange={(e) =>
                handleFieldChange(groupId, rule.id, e.target.value)
              }
              title="Select a field to filter your query"
              disabled={rule.isAuto} // Disable if the rule is auto-added
            >
              <option value="" disabled>
                Select Field
              </option>
              {availableFields.map((field, index) => (
                <option key={index} value={field.name}>
                  {rule.isAuto && rule.field === "county"
                    ? "County"
                    : field.label}
                  {field.masked && (
                    <span className="text-warning ms-2">
                      <i
                        className="bi bi-exclamation-triangle-fill"
                        title="This field is masked and contains sensitive data."
                      ></i>{" "}
                      Private
                    </span>
                  )}
                </option>
              ))}
            </Form.Select>
          </OverlayTrigger>
          {/* Warning for Masked Fields */}
          {showWarning && (
            <small className="text-warning">
              This field is private and contains sensitive data.
            </small>
          )}
        </div>

        {/* Operator Selector */}
        <div className="col-md-3">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-operator-${rule.id}`}>
                {rule.operator
                  ? `Select an operator for ${fieldDef.label}`
                  : "Select an operator to define how the field should be compared."}
              </Tooltip>
            }
          >
            <Form.Select
              value={rule.operator}
              onChange={(e) =>
                handleOperatorChange(groupId, rule.id, e.target.value)
              }
              disabled={!rule.field || rule.isAuto} // Disable if field not selected or rule is auto-added
              title="Select an operator to compare the field value"
            >
              <option value="" disabled>
                Select Operator
              </option>
              {operators.map((op) => (
                <option key={op.value} value={op.value}>
                  {op.label}
                </option>
              ))}
            </Form.Select>
          </OverlayTrigger>
        </div>

        {/* Value Input */}
        <div className="col-md-4">
          {(() => {
            if (!fieldDef || !rule.operator) return null;

            const requiresSecondValue = rule.operator === "BETWEEN";
            const noValueRequired =
              rule.operator === "IS NULL" || rule.operator === "IS NOT NULL";

            if (noValueRequired) {
              return null;
            }

            const booleanFields = [
              "use_of_certified_seeds",
              "use_of_pesticide",
              "fungicides",
              "insecticides",
              "herbicides",
              "rodenticides",
              "molluscides",
            ];
            const isBooleanField = booleanFields.includes(fieldDef.name);

            if (isBooleanField) {
              return (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-value-${rule.id}`}>
                      {fieldDef.tooltip || "Select a value to filter by."}
                    </Tooltip>
                  }
                >
                  <Form.Select
                    value={rule.value}
                    onChange={(e) =>
                      handleValueChange(groupId, rule.id, e.target.value)
                    }
                    disabled={fieldDef.disabled || rule.isAuto}
                    title={`Select a value for ${fieldDef.label}`}
                  >
                    <option value="" disabled>
                      Select Value
                    </option>
                    <option value="1">True</option>
                    <option value="0">False</option>
                  </Form.Select>
                </OverlayTrigger>
              );
            }

            // Handle other input types
            switch (fieldDef.inputType) {
              case "text":
              case "number":
              case "date":
                return (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-value-${rule.id}`}>
                        {fieldDef.tooltip || "Enter a value to filter by."}
                      </Tooltip>
                    }
                  >
                    <Form.Control
                      type={fieldDef.inputType}
                      placeholder={fieldDef.placeholder || ""}
                      value={rule.value}
                      onChange={(e) =>
                        handleValueChange(groupId, rule.id, e.target.value)
                      }
                      disabled={fieldDef.disabled || rule.isAuto}
                      title={`Enter a value for ${fieldDef.label}`}
                    />
                  </OverlayTrigger>
                );
              case "select":
                let options = fieldDef.options;
                if (rule.field === "subcounty") {
                  options = subcountyOptions;
                }
                if (rule.field === "ward") {
                  options = wardOptions;
                }
                if (rule.field === "livestock_sub_category") {
                  options = livestockSubcategoryOptions;
                }
                if (rule.field === "livestock_name") {
                  options = livestockNameOptions;
                }

                return (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-select-${rule.id}`}>
                        {fieldDef.tooltip || "Select a value to filter by."}
                      </Tooltip>
                    }
                  >
                    <Form.Select
                      value={rule.value}
                      onChange={(e) =>
                        handleValueChange(groupId, rule.id, e.target.value)
                      }
                      disabled={
                        fieldDef.disabled ||
                        rule.isAuto ||
                        (rule.field === "subcounty" && isSubcountyDisabled) ||
                        (rule.field === "ward" && isWardDisabled) ||
                        (rule.field === "livestock_sub_category" &&
                          isLivestockSubcategoryDisabled) ||
                        (rule.field === "livestock_name" &&
                          isLivestockNameDisabled)
                      }
                      title={`Select a value for ${fieldDef.label}`}
                    >
                      <option value="" disabled>
                        Select Value
                      </option>
                      {options.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>
                  </OverlayTrigger>
                );
              case "radio":
                return (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-radio-${rule.id}`}>
                        {fieldDef.tooltip || "Select an option."}
                      </Tooltip>
                    }
                  >
                    <div>
                      {fieldDef.options.map((option) => (
                        <Form.Check
                          inline
                          key={option.value}
                          label={option.label}
                          name={`${rule.id}-radio`}
                          type="radio"
                          id={`${rule.id}-radio-${option.value}`}
                          value={option.value}
                          checked={rule.value === option.value}
                          onChange={(e) =>
                            handleValueChange(groupId, rule.id, e.target.value)
                          }
                          disabled={fieldDef.disabled || rule.isAuto}
                          title={option.label}
                        />
                      ))}
                    </div>
                  </OverlayTrigger>
                );
              default:
                return (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-default-${rule.id}`}>
                        {fieldDef.tooltip || "Enter a value to filter by."}
                      </Tooltip>
                    }
                  >
                    <Form.Control
                      type="text"
                      placeholder={fieldDef.placeholder || ""}
                      value={rule.value}
                      onChange={(e) =>
                        handleValueChange(groupId, rule.id, e.target.value)
                      }
                      disabled={fieldDef.disabled || rule.isAuto}
                      title={`Enter a value for ${fieldDef.label}`}
                    />
                  </OverlayTrigger>
                );
            }
          })()}

          {/* BETWEEN Operator Second Value */}
          {rule.operator === "BETWEEN" && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-value2-${rule.id}`}>
                  Enter the second value for the BETWEEN operator.
                </Tooltip>
              }
            >
              <Form.Control
                type={fieldDef.inputType}
                className="mt-2"
                placeholder="And"
                value={rule.value2}
                onChange={(e) =>
                  handleValue2Change(groupId, rule.id, e.target.value)
                }
                disabled={fieldDef.disabled || rule.isAuto}
                title={`Enter the second value for ${fieldDef.label}`}
              />
            </OverlayTrigger>
          )}
        </div>

        {/* Remove Rule Button */}
        <div className="col-md-2">
          {!rule.isAuto && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-remove-rule-${rule.id}`}>
                  Remove this rule
                </Tooltip>
              }
            >
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => removeRule(groupId, rule.id)}
                title="Remove Rule"
              >
                <i className="bi bi-trash"></i> Remove
              </Button>
            </OverlayTrigger>
          )}
        </div>
      </div>
    </>
  );
};

Rule.propTypes = {
  rule: PropTypes.shape({
    id: PropTypes.string.isRequired,
    field: PropTypes.string,
    operator: PropTypes.string,
    value: PropTypes.any,
    value2: PropTypes.any,
    isAuto: PropTypes.bool.isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  fieldsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      inputType: PropTypes.string.isRequired,
      hidden: PropTypes.bool.isRequired,
      masked: PropTypes.bool.isRequired,
      tooltip: PropTypes.string,
      options: PropTypes.array,
    })
  ).isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleOperatorChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  handleValue2Change: PropTypes.func.isRequired,
  removeRule: PropTypes.func.isRequired,
  getFieldsForRule: PropTypes.func.isRequired,
  groupRules: PropTypes.array.isRequired,
  getCountyData: PropTypes.func.isRequired,
  getSubcountyData: PropTypes.func.isRequired,
  getLivestockCategoryData: PropTypes.func.isRequired,
  getLivestockSubcategoryData: PropTypes.func.isRequired,
  isCountyAccount: PropTypes.bool.isRequired, // New prop
};

export default Rule;
