// src/components/FormComponents/DataRequestSummaryModal.jsx

import React from "react";
import { Modal, Button, ListGroup, Alert } from "react-bootstrap";

const DataRequestSummaryModal = ({ show, onHide, selections, onSubmit }) => {
  const {
    queryBuilder,
    requestReason,
    hasSignedDSA,
    isCountyAccount,
    fieldLabels,
  } = selections;

  // Function to render selected categories, fields, and filters
  const renderCategories = () => {
    if (
      !queryBuilder ||
      !queryBuilder.payload ||
      !queryBuilder.payload.categories ||
      queryBuilder.payload.categories.length === 0
    ) {
      return <p>No categories selected.</p>;
    }

    return queryBuilder.payload.categories.map((cat, index) => (
      <div key={index} className="mb-4">
        <h6>Category: {cat.categoryId}</h6>
        <strong>Fields:</strong>
        <p>
          {cat.fields.length > 0
            ? cat.fields.map((f) => fieldLabels[f] || f).join(", ")
            : "None selected"}
        </p>
        <strong>Filters:</strong>
        {cat.filters.length > 0 ? (
          <ListGroup variant="flush">
            {cat.filters.map((filter, idx) => (
              <ListGroup.Item key={idx}>{renderFilter(filter)}</ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <p>No filters applied.</p>
        )}
      </div>
    ));
  };

  // Recursive function to render filters
  const renderFilter = (filter) => {
    if (!filter) return "";

    const { combinator, rules } = filter;

    if (!rules || rules.length === 0) return "";

    const renderedRules = rules.map((rule, index) => {
      if (rule.combinator && rule.rules) {
        // Nested group
        return `(${renderFilter(rule)})`;
      } else {
        // Single rule
        const { field, operator, value, value2 } = rule;
        let condition = "";

        const formatValue = (val) => {
          if (typeof val === "string") {
            return `"${val}"`;
          }
          return val;
        };

        const humanReadableField = fieldLabels[field] || field;

        switch (operator) {
          case "BETWEEN":
            condition = `${humanReadableField} is between ${formatValue(
              value
            )} and ${formatValue(value2)}`;
            break;
          case "BEFORE":
            condition = `${humanReadableField} is before ${formatValue(value)}`;
            break;
          case "AFTER":
            condition = `${humanReadableField} is after ${formatValue(value)}`;
            break;
          case "IS NULL":
            condition = `${humanReadableField} is null`;
            break;
          case "IS NOT NULL":
            condition = `${humanReadableField} is not null`;
            break;
          default:
            condition = `${humanReadableField} ${operator} ${formatValue(
              value
            )}`;
            break;
        }

        return condition;
      }
    });

    return renderedRules.join(` ${combinator} `);
  };

  // Function to render selected counties as a comma-separated list of names
  const renderSelectedCounties = () => {
    if (
      !queryBuilder ||
      !queryBuilder.payload ||
      queryBuilder.payload?.length === 0 ||
      !queryBuilder?.payload.some((c) =>
        c.filters.some(
          (r) =>
            r.field === "county" && (r.operator === "=" || r.operator === "in")
        )
      )
    ) {
      return "None selected";
    }
    const selectedCounties = queryBuilder?.payload
      .map((c) =>
        c.filters.some(
          (r) =>
            r.field === "county" && (r.operator === "=" || r.operator === "in")
        )
      )
      .flat();
    return selectedCounties ?? "None selected";
  };

  // Function to display whether DSA is signed and attached
  const renderDSA = () => {
    return hasSignedDSA
      ? "Data Sharing Agreement signed and attached."
      : "Data Sharing Agreement not signed or not attached.";
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Your Selections</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* County Account Information */}
        {isCountyAccount && (
          <Alert variant="info" className="mb-4">
            As a county account, your data request results will be limited to
            your specific county.
          </Alert>
        )}

        {/* General Warning */}
        <Alert variant="warning" className="mb-4">
          Please review your selections below. Once submitted, your request
          <b> cannot</b> be edited.
        </Alert>

        {/* Summary of Selections */}
        <ListGroup variant="flush">
          {/* Query Builder Selections */}
          <ListGroup.Item className="mb-3">
            <strong>Query Builder Selections:</strong>
            {renderCategories()}
          </ListGroup.Item>

          {/* Reason for Request */}
          <ListGroup.Item className="mb-3">
            <strong>Reason for Request:</strong>{" "}
            {requestReason.trim().length > 0 ? requestReason : "None provided"}
          </ListGroup.Item>

          {/* Data Sharing Agreement */}
          {!isCountyAccount && (
            <ListGroup.Item className="mb-3">
              <strong>Data Sharing Agreement:</strong> {renderDSA()}
            </ListGroup.Item>
          )}

          {/* Selected Counties */}
          {!isCountyAccount && (
            <ListGroup.Item className="mb-3">
              <strong>Selected Counties:</strong> {renderSelectedCounties()}
            </ListGroup.Item>
          )}

          {/* For County Accounts, show their single selected county */}
          {isCountyAccount && (
            <ListGroup.Item className="mb-3">
              <strong>County:</strong> {renderSelectedCounties()}
            </ListGroup.Item>
          )}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Go Back and Edit
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Confirm and Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataRequestSummaryModal;
